tr{
	vertical-align: middle;
}
th,td{
	padding: 10px 0;
	text-align: center;
}
tr th:first-child{
	width: 25%;
}
tr th:nth-child(2){
	width: 25%;
}
tr th:nth-child(3){
	width: 25%;
}
tr th:nth-child(4){
	width: 25%;
}